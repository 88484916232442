<script setup lang="ts">
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/style.css";

const { getPaletteForPostType } = useTheme();
const { savedPost, savedImage, isSaveOpen } = usePostSave();

const postType = computed(() => savedPost.value?.type);

const { postToGrid } = useGrid();
const gridItem = computed(() => postToGrid(savedPost, savedImage));

const {
  getBoards,
  createBoard,
  hasBoardImage,
  addImageToBoard,
  removeImageFromBoard,
} = useBoards();
const { currentUser } = useAuth();

const { data, execute, status } = useAsyncData(
  "user:boards:save",
  async () => await getBoards().catch(() => []),
  { watch: [currentUser] }
);

const isCreatingNewBoard = ref(false);

const creationErrors = ref<string[]>([]);
const newBoardName = ref("");

const startCreatingNewBoard = () => {
  isCreatingNewBoard.value = true;
  newBoardName.value = "";
  creationErrors.value = [];
};

const stopCreatingNewBoard = () => {
  isCreatingNewBoard.value = false;
  newBoardName.value = "";
  creationErrors.value = [];
};

const isCreationLoading = ref(false);
const recentlyCreatedBoard = ref<BoardItem | null>(null);

const createNewBoard = async () => {
  creationErrors.value = [];

  if (!currentUser.value) {
    creationErrors.value.push("Musisz być zalogowany, aby utworzyć tablicę.");
    return;
  }

  if (!newBoardName.value || newBoardName.value.length < 3) {
    creationErrors.value.push(
      "Nazwa tablicy musi zawierać co najmniej 3 znaki."
    );
    return;
  }

  if (newBoardName.value.length > 20) {
    creationErrors.value.push("Nazwa tablicy nie może przekraczać 20 znaków.");
    return;
  }

  isCreationLoading.value = true;

  await createBoard({
    title: newBoardName.value,
    owner: currentUser.value.id as any,
  })
    .then((result) => {
      stopCreatingNewBoard();
      recentlyCreatedBoard.value = result[0];
    })
    .catch(() => {
      creationErrors.value.push("Wystąpił błąd podczas tworzenia tablicy.");
    })
    .finally(() => {
      isCreationLoading.value = false;
      execute();
    });
};

const isAddingLoadingItems = ref<Record<string, boolean>>({});
const isAddingLoading = ref(false);

const addToBoard = async (board: BoardItem) => {
  if (!currentUser.value || !board.id) {
    return;
  }

  isAddingLoading.value = true;
  isAddingLoadingItems.value[board.id] = true;

  if (await hasBoardImage(board, savedImage.value!)) {
    await removeImageFromBoard(board, savedImage.value!)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        isAddingLoading.value = false;

        if (board.id) {
          isAddingLoadingItems.value[board.id] = false;
        } else {
          isAddingLoadingItems.value = {};
        }

        execute();
      });
  } else {
    await addImageToBoard(board, savedImage.value!, savedPost.value)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        isAddingLoading.value = false;

        if (board.id) {
          isAddingLoadingItems.value[board.id] = false;
        } else {
          isAddingLoadingItems.value = {};
        }

        execute();
      });
  }
};

const isLoading = computed(
  () =>
    status.value === "pending" ||
    isCreationLoading.value ||
    isAddingLoading.value
);

const title = computed(() =>
  isCreatingNewBoard.value
    ? "Nadaj nazwę nowej tablicy"
    : "Dodaj zdjęcie do tablicy"
);

whenever(isSaveOpen, () => {
  recentlyCreatedBoard.value = null;
  execute();
});

watch(isSaveOpen, (value) => {
  if (!value) {
    stopCreatingNewBoard();
    recentlyCreatedBoard.value = null;
    isAddingLoading.value = false;
    isAddingLoadingItems.value = {};
    isCreationLoading.value = false;
  }
});

const boards = computed(() => {
  const boards = [];

  if (recentlyCreatedBoard.value) {
    const recent =
      data.value?.find((item) => item.id === recentlyCreatedBoard.value?.id) ||
      recentlyCreatedBoard.value;

    boards.unshift(recent);

    if (data.value)
      boards.push(
        ...data.value?.filter(
          (item) => item.id !== recentlyCreatedBoard.value?.id
        )
      );
  } else {
    boards.push(...(data.value || []));
  }

  return boards;
});

const { isMobile } = useUserDevice();
const { getAllPostMedia } = usePostGallery();

const boardCover = computed(
  () =>
    getAllPostMedia(savedPost.value).find(
      ({ collection, item }) => collection === "images" && item?.file?.id
    )?.item?.file?.id
);
</script>

<template>
  <AppDialog
    v-model:is-open="isSaveOpen"
    v-bind="{ title }"
    class="post-save"
    :class="[
      getPaletteForPostType(postType).scrollbar,
      getPaletteForPostType(postType).selection,
    ]"
    :drawer="isMobile"
  >
    <template v-if="boardCover" #drawer-bg>
      <NuxtPicture
        provider="directus"
        :src="boardCover"
        class="grow w-full h-full"
        :img-attrs="{ class: 'w-full h-full object-cover' }"
        quality="75"
        :alt="savedPost.value?.title"
      />
    </template>

    <template #side>
      <div
        class="post-save__side absolute inset-0 grid grid-cols-[22.75rem] justify-center items-center p-16"
        :class="[getPaletteForPostType(postType).bg]"
      >
        <PostGridItem :item="gridItem" :interactive="false" />
      </div>
    </template>

    <template #default>
      <div class="post-save__content h-full">
        <div
          class="post-save__content-wrapper h-full flex flex-col items-stretch gap-6"
        >
          <!-- Create new board -->
          <template v-if="isCreatingNewBoard">
            <div class="grow flex flex-col items-stretch">
              <p :class="[{ 'text-center': isMobile }]">
                Nazwa tablicy nie może przekraczać 20 znaków.
              </p>

              <div
                class="grow flex flex-col items-stretch justify-center py-[6.25rem]"
              >
                <AppInput
                  v-model="newBoardName"
                  label="Nazwa tablicy"
                  :error="creationErrors.length > 0"
                  :hint="creationErrors[0]"
                />
              </div>
            </div>

            <div class="flex flex-col items-stretch justify-center gap-6">
              <AppBtn
                variant="text"
                @click="stopCreatingNewBoard"
                :disabled="isLoading"
              >
                <Icon name="ns:arrow-left" class="w-6 h-6" />
                <span>Wróć do wyboru</span>
              </AppBtn>

              <AppBtn
                variant="solid"
                @click="createNewBoard"
                :disabled="isLoading"
              >
                <span>Utwórz tablicę</span>
                <Icon name="ns:folder-plus" class="w-6 h-6" />
              </AppBtn>
            </div>
          </template>

          <!-- Boards list -->
          <template v-else>
            <div v-if="data && data.length > 0" class="grow min-h-0 mt-7">
              <PerfectScrollbar
                class="min-h-[18.5rem] h-full max-h-[26.4375rem] -me-4 pe-4"
              >
                <div class="flex flex-col items-stretch gap-4">
                  <PostSaveBoard
                    v-for="board in boards"
                    :key="board.id"
                    v-bind="{ board }"
                    :is-active="
                      !!board.items?.find(
                        (item) => item.images_id?.id === savedImage?.id
                      )
                    "
                    @click="
                      () =>
                        (
                          board?.id
                            ? isAddingLoadingItems[board.id]
                            : isAddingLoading
                        )
                          ? void 0
                          : addToBoard(board)
                    "
                    :is-loading="
                      board?.id
                        ? isAddingLoadingItems[board.id]
                        : isAddingLoading
                    "
                  />
                </div>
              </PerfectScrollbar>
            </div>

            <div v-else class="grow">
              <p>
                Nie posiadasz jeszcze żadnej tablicy.<br />
                Użyj przycisku poniżej aby utworzyć pierwszą.<br />
                Maksymalnie możesz utworzyć ich 20.
              </p>
            </div>

            <div class="flex flex-col items-stretch justify-center gap-6">
              <AppBtn variant="solid" @click="startCreatingNewBoard">
                <span>Utwórz nową tablicę</span>
                <Icon name="ns:folder-plus" class="w-6 h-6" />
              </AppBtn>
            </div>
          </template>
        </div>
      </div>
    </template>
  </AppDialog>
</template>
