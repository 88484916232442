<script setup lang="ts">
const props = defineProps<{
  board: BoardItem;
  isActive?: boolean;
  isLoading?: boolean;
}>();
</script>

<template>
  <button
    class="post-save-board border-[0.0625rem] border-grey-300 rounded-full select-none overflow-hidden group/board-button"
    :class="[isLoading ? 'opacity-50 cursor-wait' : 'cursor-pointer']"
  >
    <div
      class="post-save-board__wrapper flex items-center justify-stretch gap-0"
    >
      <div
        class="post-save-board__image shrink basis-12 w-12 h-12 min-w-12 bg-grey-300"
      >
        <NuxtPicture
          v-if="props.board.items?.[0]?.images_id?.file?.id"
          class="post-save-board__image-wrapper relative block w-12 h-12"
          provider="directus"
          :width="48"
          :height="48"
          :src="props.board.items[0].images_id.file.id"
          :img-attrs="{ class: 'absolute inset-0 w-full h-full object-cover' }"
          sizes="40px xs:48px sm:80px md:100px lg:35px xl:48px xxl:48px"
          quality="75"
          :alt="props.board.title"
        />

        <div
          v-else
          class="w-12 h-12 flex items-center justify-center text-white bg-grey-300"
        >
          <Icon name="ns:image" class="block w-6 h-6" />
        </div>
      </div>

      <div class="post-save-board__content grow max-w-full min-w-0">
        <div
          class="post-save-board__content-wrapper px-4 flex items-center justify-start max-w-full min-w-0"
        >
          <div
            class="post-save-board__title max-w-full min-w-0 overflow-hidden whitespace-nowrap text-ellipsis"
          >
            <span>{{ props.board.title }}</span>
          </div>
        </div>
      </div>

      <div class="post-save-board__actions pe-6">
        <div
          class="post-save-board__actions-wrapper flex items-center justify-end gap-4"
        >
          <span
            v-if="isLoading"
            class="block w-[1.125rem] h-[1.125rem] rounded-full border-[0.0625rem] border-black border-b-transparent animate-[spin_0.5s_linear_infinite]"
          >
          </span>
          <span
            v-else
            class="flex items-center justify-center text-center border-[0.0625rem] border-black rounded-full h-[1.125rem] min-w-[1.125rem] text-sm leading-[1] font-semibold px-1"
          >
            {{ props.board.items?.length }}
          </span>
          <Icon
            name="ns:heart-plus"
            class="block w-6 h-6"
            :class="[
              isActive
                ? 'text-black'
                : isLoading
                ? 'text-grey-300'
                : 'text-grey-300 group-hover/board-button:text-black',
            ]"
          />
        </div>
      </div>
    </div>
  </button>
</template>
